<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR CUPON </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'cuponesPanel' }"
                    >Cupones</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{
                      name: 'cuponesEditarPanel',
                      params: { idcupon: $route.params.idcupon },
                    }"
                    >Editar</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="nombre"
                    item-value="idtipo_descuento"
                    :items="arrayTipoDescuento"
                    label="Tipo de descuento (*)"
                    outlined
                    v-model="cupon.idtipo_descuento"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Nombre (*)"
                    v-model="cupon.nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    type="number"
                    min="0"
                    dense
                    label="Monto"
                    v-model="cupon.monto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    type="number"
                    min="0"
                    dense
                    label="Monto Minimo"
                    v-model="cupon.monto_minimo"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-menu :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatFechaDesde"
                        dense
                        clearable
                        label="Desde"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="cupon.fecha_desde = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="cupon.fecha_desde"
                      locale="es-es"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-menu :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatFechaHasta"
                        dense
                        clearable
                        label="Hasta"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="cupon.fecha_hasta = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="cupon.fecha_hasta"
                      locale="es-es"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="nombre"
                    item-value="idtipo_uso"
                    :items="arrayTipoUso"
                    label="Tipo de uso (*)"
                    outlined
                    v-model="cupon.idtipo_uso"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="nombre"
                    item-value="idcupon_modelo_producto"
                    :items="arrayTipoLente"
                    label="Tipo de lente (*)"
                    outlined
                    v-model="cupon.idcupon_modelo_producto"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="nombre"
                    item-value="idtipo_usuario"
                    :items="arrayTipoUsuario"
                    label="Tipo de usuario (*)"
                    outlined
                    v-model="cupon.idtipo_usuario"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-autocomplete
                    clearable
                    dense
                    v-model="cupon.idcliente"
                    :items="arrayUsuarios"
                    :loading="loadingSelectUsuarios"
                    :search-input.sync="searchUsuarios"
                    hide-no-data
                    item-text="nombreCompleto"
                    item-value="idcliente"
                    label="Usuario"
                    placeholder="Buscar..."
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="cmbEstado"
                    label="Estado(*)"
                    outlined
                    v-model="cupon.estado"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'cuponesPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cupon: {
        _method: "PUT",
        idcupon: "",
        idtipo_descuento: "",
        idtipo_usuario: "",
        idtipo_uso: "",
        idcliente: "",
        idcupon_modelo_producto: "",
        nombre: "",
        monto: "",
        fecha_desde: "",
        fecha_hasta: "",
        monto_minimo: "",
        estado: 1,
      },
      arrayTipoDescuento: [],
      arrayTipoUsuario: [],
      arrayTipoUso: [],
      arrayTipoLente: [],
      arrayUsuarios: [],
      loadingSelectUsuarios: false,
      searchUsuarios: null,
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
    };
  },

  computed: {
    formatFechaDesde() {
      return this.cupon.fecha_desde
        ? this.$moment(this.cupon.fecha_desde).format("DD/MM/YYYY")
        : "";
    },
    formatFechaHasta() {
      return this.cupon.fecha_hasta
        ? this.$moment(this.cupon.fecha_hasta).format("DD/MM/YYYY")
        : "";
    },
  },

  watch: {
    searchUsuarios(val) {
      this.getClientes(val);
    },
  },

  methods: {
    getClientes(val) {
      this.loadingSelectUsuarios = true;
      this.axios({
        method: "POST",
        url: "api/panel/cupones/getClientes",
        data: {
          txtBuscar: val,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.arrayUsuarios = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.loadingSelectUsuarios = false;
        });
    },

    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/cupones/" + this.$route.params.idcupon + "/edit",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.arrayTipoDescuento = response.data.data.tipoDescuento;
            this.arrayTipoUso = response.data.data.tipoUso;
            this.arrayTipoLente = response.data.data.tipoLente;
            this.arrayTipoUsuario = response.data.data.tipoUsuario;

            let cupon = response.data.data.cupon;

            this.cupon.idcupon = cupon.idcupon;
            this.cupon.idtipo_descuento = cupon.idtipo_descuento;
            this.cupon.idtipo_usuario = cupon.idtipo_usuario;
            this.cupon.idtipo_uso = cupon.idtipo_uso;
            this.cupon.idcliente = cupon.idcliente;
            this.cupon.nombre = cupon.nombre;
            this.cupon.monto = cupon.monto;
            this.cupon.fecha_desde = cupon.fecha_desde;
            this.cupon.fecha_hasta = cupon.fecha_hasta;
            this.cupon.monto_minimo = cupon.monto_minimo;
            this.cupon.idcupon_modelo_producto = cupon.idcupon_modelo_producto;

            if (cupon.cliente != null) {
              this.getClientes(
                cupon.cliente.codigo_cliente +
                  " " +
                  cupon.cliente.apellidos +
                  " " +
                  cupon.cliente.nombres
              );
            }

            this.cupon.estado = cupon.estado;
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router.push({
            name: "cuponesPanel",
          });
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;

      this.axios({
        method: "POST",
        url: "api/panel/cupones/" + this.cupon.idcupon,
        data: this.serialize(this.cupon),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "cuponesPanel",
            });
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    if (!this.validarPermiso("cupones.editar")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>

<style scoped>
.cursor-move {
  cursor: move;
}
</style>
